import { FC } from 'react';
import { redirectToRegion } from 'src/utils/redirectToRegion';

import {
  RegionConfiguration,
  RegionsGroupConfiguration,
} from '@ac/library-utils/dist/declarations/config/ac.config';
import {
  ButtonPattern,
  ButtonTheme,
  DropdownButtonItemType,
  DropdownButtonSlot,
  IconName,
} from '@ac/web-components';

import './RegionButton.css';

interface Props {
  regionsGroup: RegionsGroupConfiguration;
  regionsInGroup: RegionConfiguration[];
}

interface SingleRegionInGroupButtonProps {
  region: RegionConfiguration;
}

const SingleRegionInGroupButton: FC<SingleRegionInGroupButtonProps> = ({
  region,
}: SingleRegionInGroupButtonProps) => (
  <ac-button
    pattern={ButtonPattern.primary}
    theme={ButtonTheme.light}
    onClick={(): void => redirectToRegion(region)}
    class="regions-button-container"
  >
    <ac-button-content text={region.name} icon={IconName.shiji} />
  </ac-button>
);

const MultiRegionsInGroupButton: FC<Props> = ({
  regionsGroup,
  regionsInGroup,
}) => (
  <ac-dropdown-button
    pattern={ButtonPattern.primary}
    theme={ButtonTheme.light}
    isArrowVisible={true}
    class="regions-button-container"
    isBoundaryContainerApplied={false}
  >
    <ac-button-content text={regionsGroup.name} icon={IconName.shiji} />

    {regionsInGroup.map((regionDropdown) => (
      <ac-dropdown-button-item
        label={regionDropdown.name}
        type={DropdownButtonItemType.default}
        slot={DropdownButtonSlot.items}
        key={regionDropdown.code}
        onClick={(): void => redirectToRegion(regionDropdown)}
      />
    ))}
  </ac-dropdown-button>
);

export const RegionButton: FC<Props> = ({ regionsGroup, regionsInGroup }) => {
  const region = regionsInGroup[0];
  const manyRegionsInGroup = regionsInGroup.length > 1;

  return (
    <ac-box
      sizeSm={0}
      sizeMd={16}
      class={`regions-region-button-${regionsGroup.code.toLowerCase()}`}
    >
      {manyRegionsInGroup ? (
        <MultiRegionsInGroupButton
          regionsGroup={regionsGroup}
          regionsInGroup={regionsInGroup}
        />
      ) : (
        <SingleRegionInGroupButton region={region} />
      )}
    </ac-box>
  );
};
