import { FC } from 'react';

import {
  IconName,
  JustifyContent,
  TextColor,
  TextSize,
  TextWeight,
} from '@ac/web-components';

import './Footer.css';

export const Footer: FC = () => (
  <ac-flex justifyContent={JustifyContent.center}>
    <ac-box sizeSm={9}>
      <ac-flex justifyContent={JustifyContent.center} class="regions-footer">
        <ac-box sizeSm={2} sizeMd={0}>
          <ac-text
            color={TextColor.primaryDark}
            size={TextSize.lg}
            weight={TextWeight.semibold}
          >
            <ac-icon
              icon={IconName.rotateRight}
              dynamicClass="regions-rotate icon-font"
            />
          </ac-text>
        </ac-box>
        <ac-box sizeSm={10} sizeMd={0}>
          <ac-text
            color={TextColor.primaryDark}
            size={TextSize.lg}
            weight={TextWeight.semibold}
          >
            {'For better readability,'}
            <br />
            {'rotate your device horizontally.'}
          </ac-text>
        </ac-box>
      </ac-flex>
    </ac-box>
  </ac-flex>
);
